<template>
  <v-card>
    <v-card-title class="text-h5" primary-title>
      <v-icon left v-text="'$alert'" />
      <h6 class="text-h6">
        {{ $trans("remove_asset") }}
      </h6>
    </v-card-title>

    <v-card-text>
      <p class="text-subtitle-1">
        {{
          $trans("remove_asset_warning", { file: getConfirmDialog.data.name })
        }}
      </p>
      <p class="accent_light--text text-subtitle-1">
        {{ $trans("no_way_to_undo_action") }}
      </p>
    </v-card-text>

    <v-card-actions>
      <v-spacer />
      <v-btn color="light" text :disabled="isLoading" @click="$emit('close')">
        {{ $trans("cancel") }}
      </v-btn>
      <v-btn
        color="accent_light"
        text
        :disabled="isLoading"
        @click="removeAsset(getConfirmDialog.data.id)"
      >
        {{ $trans("remove") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapMutations } from "vuex";
import api from "@/lib/calendesk-js-library/api/api";
import { pushEvent } from "@/lib/calendesk-js-library/tools/helpers";
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";

export default {
  name: "RemoveAssetDialog",
  mixins: [sharedActions],
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    ...mapMutations({
      setReload: "assets/SET_RELOAD",
    }),
    removeAsset(id) {
      this.$emit("loading", true);
      this.isLoading = true;

      pushEvent("removeImageAsset");

      api
        .removeAssetUser(id)
        .then(() => {
          this.setReload();
        })
        .catch((error) => this.$log.error(error))
        .finally(() => {
          this.isLoading = false;
          this.$emit("close");
          this.$emit("loading", false);
        });
    },
  },
};
</script>
